body {
    overflow-x: hidden;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

    #sidebar-wrapper .sidebar-heading {
        padding: 0.875rem 1.25rem;
        font-size: 1.2rem;
    }

    #sidebar-wrapper .list-group {
        width: 15rem;
    }

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }
    
    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

/* added */
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar-wrapper {
    background: #1c2833 !important;
}
.nav-body {
    padding: 5px !important;
    padding-top: 0px !important;
}

#accordion_menu *.nav-link{
    background: #1c2833;
    margin-bottom: 1px;
    color: #7fb3d5;
    border-right: 1px #343a40 solid !important;
    border-left: 1px #343a40 solid !important;
}
.nav-body ul li a.nav-link {
    background: #17202a !important;
    color: #229954 !important;
}
#accordion_menu *.nav-link:hover, .nav-body ul li a.nav-link:hover {
    background: #154360 !important;
    color: #eeeeee !important;
    cursor: pointer;
}
.nav-body ul li a.nav-link-active, .nav-link-active {
    background: #2255a4 !important;
    background-color: #2255a4 !important;
    color: #FFF !important;
}
.profile-link{
    cursor:pointer;
    color:#CCC;
    text-decoration:none !important;
}
.profile-link:hover {
    color: orange !important;
}