.w-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}
.x5-col{
    padding:12px;
    padding-top:0px;
    padding-bottom:15px;
}
@media (min-width: 300px) and (max-width:649px) {
    .w-sm-20 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .x5-col {
        padding: 1px;
        padding-top: 0px;
        width:100%;
        padding-bottom: 8px;
    }
}

@media (min-width: 650px) {
    .w-md-20 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20% !important;
        flex: 0 0 20% !important;
        max-width: 20% !important;
    }
    .x5-col {
        padding: 1px;
        padding-top: 0px;
        width: 20%;
        padding-bottom: 8px;
    }
}
.x5-col > canvas{
    width:100%;
}
@media (min-width: 992px) {
    .w-lg-20 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .x5-col {
        padding: 8px;
        padding-top: 0px;
        width:20%;
        padding-bottom: 12px;
    }
}

@media (min-width: 1200px) {
    .w-xl-20 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .x5-col {
        padding: 15px;
        padding-top: 0px;
        padding-bottom: 18px;
    }
}
@media (min-width: 1400px) {
    .w-xl-20 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .x5-col {
        padding: 2%;
        padding-top: 0px;
        width: 20%;
        padding-bottom: 20px;
    }
}
