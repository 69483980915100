$darkNavy: #213140;
$teal1: #000000;
$teal2: #e13738;
$charcoal: #555555;
$gold: #B6985A;

$activeShadow: 0 0 10px rgba($teal1, .5);

/* MIXINS */
@mixin focusOutline {
    outline: dotted 1px #CCC;
    outline-offset: .45rem;
}

@mixin hideInput {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
}

@mixin breakpoint($point) {
    @if $point == 1100 {
        @media (max-width: 100%) {
            @content;
        }
    }
    @else if $point == 800 {
        @media (max-width: 100%) {
            @content;
        }
    }
}

/* TOGGLE STYLING */
.radio-toggle {
    margin: 0 0 1rem;
    box-sizing: border-box;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    input {
        @include hideInput;
    }

    input + label {
        margin: 0;
        padding: .5rem 1.5rem;
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        border: solid 1px #d0373a;
        background-color: #FFF;
        font-size: 0.9rem;
        line-height: 140%;
        font-weight: 500;
        text-align: center;
        box-shadow: 0 0 0 rgba(255,255,255,0);
        transition: border-color .15s ease-out, color .25s ease-out, background-color .15s ease-out, box-shadow .15s ease-out;
        /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
        /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
        /* ----- */
        &:first-of-type {
            border-radius: 6px 0 0 6px;
            border-right: none;
        }

        &:last-of-type {
            border-radius: 0 6px 6px 0;
            border-left: none;
        }
    }

    input:hover + label {
        border-color: $darkNavy;
        background: #CCC;
    }

    input:active + label {
        border-color: $darkNavy;
        background: #eeeeee;
    }

    input:checked + label {
        background-color: $teal2;
        color: #FFF;
        box-shadow: $activeShadow;
        border-color: $teal2;
        z-index: 1;
    }

    input:focus + label {
        @include focusOutline;
    }

    @include breakpoint(800) {
        input + label {
            padding: .75rem .25rem;
            flex: 0 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
