.btn-col-05 {
    width: 80px;
}
.btn-col-1 {
    width: 90px;
}
.btn-col-2 {
    width: 170px;
}
.btn-col-3 {
    width: 220px;
}
.alet-err-message {
    max-height: 350px;
    overflow-y: scroll;
}
.text-bold{
    font-weight:bold;
    color:#25394c
}
.chart-data {
    overflow: auto;
}
.col-half-offset {
    margin-left: 4.166666667%
}
.align-items-bottom{
    margin-bottom:0px !important;
}
.table-data-list tbody th {
    text-align: right;
    width: 30%;
}
#element_config {
    margin-left: 30px;
    margin-top: -10px;
    background: #ebeced;
}
.table thead {
    background: #BBB;
}
.margin-bottom-20 {
    margin-bottom: 20px !important;
}
.card-title-border {
    border-bottom: 1px red dotted;
    margin-bottom: 25px;
    padding-bottom: 5px;
    color: darkorchid;
}
.col-btn1{
    width:75px;
    text-align:center;
}
.col-btn1 {
    width: 180px;
    text-align: center;
}
.hidden {
    display: none;
}
.margin-right-5 {
    margin-right:5px !important;
}
.width-100{
    width:100% !important;
}
.accordion > * {
    text-align:left !important;
}

.margin-top-4{
    margin-top:4px;
}

.bg-row-active, .bg-row-active td {
    background: #b0d5c5;
}
.height_500 {
    min-height: 500px !important;
}

/*.ck-editor__editable{
    height: 400px !important;
}*/
.ck-editor__editable_inline {
    min-height: 500px;
}

.font-size-60 {
    font-size: 60px;
}
.font-size-50 {
    font-size: 40px;
}
.hib-text h5{
    padding-top:10px;
    font-weight:normal;
    font-size:18px;
}
.hib-text h6{
    padding-top:10px;
    font-weight:bold !important;
    font-size:18px;
    color:darkslategray;

}
.site-navbar {
    height: 77px;
}
.site-logo{
    margin-top:25px;
}
.margin-right-10 {
    margin-right: 10px;
}
.list-group-menu a.active {
    color: red;
    border-color: #BBB;
    background:#ebeced !important;
}
/* ----------------
 Responsive
  ---------------------*/
@media (min-width: 1200px) {
    .container {
     max-width: 1176px;
   }
 }
.total-record{
    float:left;
    margin-right:20px;
    margin-top:8px;
    font-size:14px;
    color:#6c3838;
}
@media only screen and (min-width: 1200px) and (max-width: 1595px) {
    .site-logo {
     margin-right: 20px;
     padding-left: 15px;
   }
   .header-right {
     padding-right: 60px;
     margin-bottom:0px;
   }
   .header-info-box {
     margin-right: 0;
   }
   .site-nav-menu ul li a {
     margin-right: 0;
   }
   .search-switch {
     width: 50px;
     font-size: 16px;
     padding:0px;
     margin:0px;
   }
 }
 
 .header-section {
   background: #ffffff;
 }
/* Medium screen : 992px. */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .site-navbar {
     text-align: left;
   }
   .site-logo {
     display: inline-block;
     padding-top: 0px;
     padding-left: 20px;
     margin-right: 0;
     margin-bottom: 20px;
     text-align: center;
   }
   .header-right {
     padding-right: 50px;
     text-align: center;
     display: inline-block;
     float: right;
   }
    .header-info-box {
        margin-right: 15px;
        padding: 21px 0 0;
    }
   .site-nav-menu {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
   }
   .search-switch {
     width: 50px;
     font-size: 16px;
     height: 50px;
     border-radius: 50%;
     top: 15px;
     background:#000;
   }
   .footer-social a {
     margin-right: 4px;
   }
 }
 
 /* Tablet :768px. */
 
 @media only screen and (min-width: 768px) and (max-width: 991px) {
   .site-navbar {
     text-align: center;
   }
   .site-logo {
     display: inline-block;
     padding-top: 0px;
     padding-left: 0;
     margin-right: 0;
     margin-bottom: 20px;
     text-align: center;
   }
   .header-right {
     padding-right: 50px;
     text-align: center;
     display: inline-block;
     float: none;
   }
   .header-info-box {
     margin-right: 15px;
     padding: 21px 0 0;
     display:none;
   }
   .site-nav-menu {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
   }
   .search-switch {
     width: 50px;
     font-size: 16px;
     height: 50px;
     border-radius: 50%;
     top: 15px;
     display:none;
   }
   .hero-slider .owl-nav button.owl-prev,
   .hero-slider .owl-nav button.owl-next {
     position: absolute;
     width: 60px;
     height: 60px;
     top: calc( 50% - 30px);
     font-size: 18px;
   }
   .hero-item h2 {
     font-size: 60px;
   }
   .video-box {
     height: 500px;
     margin-top: 40px;
   }
   .cta-section {
     text-align: center;
   }
   .cta-section h2 {
     margin-bottom: 30px;
   }
   .footer-buttom {
     padding-top: 30px;
   }
   .footer-menu {
     text-align: center;
   }
   .footer-menu li:first-child a {
     margin-left: 0;
   }
   .copyright {
     text-align: center;
     padding-bottom: 30px;
   }
   .about-text {
     padding-top: 50px;
   }
   .reserch-tab-menu {
     margin-bottom: 30px;
   }
   .sidebar {
     padding-top: 50px;
   }
 }
 
 /* Large Mobile :480px. */
 
 @media only screen and (max-width: 767px) {
   .header-top {
     padding: 14px 0;
     text-align: center;
   }
   .header-top p {
     line-height: 1.5;
   }
   .site-navbar {
     text-align: center;
   }
    .site-logo {
        display: inline-block;
        padding-top: 0px;
        padding-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        text-align: center;
    }
   .header-right {
     display: none;
   }
   .header-info-box {
     margin-right: 15px;
     padding: 21px 0 0;
     vertical-align:middle;
   }
   .header-info-box .hib-text {
     text-align: left;
   }
   .site-nav-menu {
     display: none;
   }
   .search-switch {
     display: none;
   }
   .slicknav_menu {
     display: block;
     text-align: center;
     background: transparent;
   }
   .slicknav_btn {
     margin: 0 auto 20px;
     background: #081624;
     float: none;
     display: inline-block;
   }
   .slicknav_nav {
     background: #081624;
     text-align: left;
     padding: 8px 5px;
     margin-bottom: 15px;
   }
   .slicknav_nav a:hover,
   .slicknav_nav .slicknav_row:hover {
     background: #25394c;
     color: #fff;
   }
   .hero-item {
     text-align: center;
   }
   .hero-item h2 {
     font-size: 50px;
   }
   .hero-item h2 span:nth-child(1) {
     /* color: #081624; */
     padding-right: 15px;
   }
   .hero-slider .owl-nav button.owl-prev,
   .hero-slider .owl-nav button.owl-next {
     position: absolute;
     width: 40px;
     height: 40px;
     top: calc( 50% - 20px);
     font-size: 14px;
   }
   .video-box {
     height: 500px;
     margin-top: 40px;
   }
   .cta-section {
     text-align: center;
   }
   .cta-section h2 {
     margin-bottom: 30px;
   }
   .footer-buttom {
     padding-top: 30px;
   }
   .footer-menu {
     text-align: center;
   }
   .footer-menu li:first-child a {
     margin-left: 0;
   }
   .copyright {
     text-align: center;
     padding-bottom: 30px;
   }
   .about-text {
     padding-top: 50px;
   }
   .reserch-tab-menu {
     margin-bottom: 30px;
   }
   .circle-progress-warp .circle-progress {
     padding-top: 0;
   }
   .sidebar {
     padding-top: 50px;
   }
   .search-model-form input {
     width: 100%;
   }
 }
 
 /* Small Mobile :320px. */
 
 @media only screen and (max-width: 479px) {
   .hero-item h2 {
     font-size: 45px;
   }
   .hero-item h2 span:nth-child(1) {
     /* color: #081624; */
     padding-right: 15px;
   }
   .map-section {
     padding-bottom: 0;
   }
   .map-info {
     max-width: 100%;
   }
   .map {
     position: relative;
     height: 400px;
     margin-top: 70px;
   }
   .footer-menu li a {
     margin-left: 0;
     padding: 13px 10px;
   }
    .search-switch{
        display:none;
    }
 }
 