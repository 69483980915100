.login-main-text{
  padding: 20px;
  padding-top: 30px;
  height: 100%;
}
.login-form{
    margin: auto;    
    width:auto;
    max-width: 400px;
}
.space-btm{
    padding: 100px;
}
