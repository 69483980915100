
.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 70%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 0 !important;
}
.connecting-line-step-1 {
    height: 2px;
    width:30%;
    background: #7e171b;
}
.connecting-line-step-2 {
    height: 2px;
    width: 70%;
    background: #7e171b;
}
.connecting-line-step-3 {
    height: 2px;
    width: 100%;
    background: #7e171b;
}
.font-size-26 {
    font-size:26px;
}
.text-inline-ans {
    margin-right:10px;
    font-weight:bold;
    float: right;
    color:#CCC;
}
.wizard {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: table;
    text-align: center;
    height: 70px;
    background: #fafafa url(/img/patterns/geometry.png);
    background-size: 30%;
}
    .wizard .wizard-col {
        width: 25%;
        display: table-cell;
        vertical-align: middle;
        padding: 0;
        margin: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        height: 70px;
        text-align: center;
    }

span.round-tab {
    width: 70px;
    height: 70px;
    display: inline-block;
    position: relative;
    border-radius: 100px;
    background: #fff;
    border: 2px solid #e0e0e0;
    z-index: 5 !important;
    left: 0;
    text-align: center;
    font-size: 45px;
}

    span.round-tab i {
        color: #999999;
    }

    span.round-tab.active {
        background: #fff;
        border: 2px solid #7e171b;
        background: #eaeaea;
    }
    span.round-tab.active i {
        color: #7e171b;
    }
span.round-tab.active.current i {
    color: #28a745;
}
/* #28a745;
 span.round-tab:hover {
    color: #333;
    border: 2px solid #cbd1d8;
} */

/* Radio btn*/
@keyframes click-wave {
    0% {
        height: 40px;
        width: 40px;
        opacity: 0.35;
        position: relative;
    }

    100% {
        height: 200px;
        width: 200px;
        margin-left: -80px;
        margin-top: -80px;
        opacity: 0;
    }
}

.option-x-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 40px;
    transition: all 0.15s ease-out 0s;
    background: #cbd1d8;
    border: none;
    color: #FFFFFF !important;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
}

.option-x-input:hover {
    background: #9faab7;
}

.option-x-input:checked {
    /*background: #40e0d0;*/
    background: #818181;
}

.option-x-input:checked::before {
     font-family: FontAwesome;
    height: 40px;
    width: 40px;
    position: absolute;
    content: '\f00c';
    display: inline-block;
    font-size: 26.66667px;
    text-align: center;
    line-height: 40px;
    color: #FFFFFF;
}
/*✔*/
    .option-x-input:checked::after {
        -webkit-animation: click-wave 0.65s;
        -moz-animation: click-wave 0.65s;
        animation: click-wave 0.65s;
        background: #40e0d0;
        content: '';
        display: block;
        position: relative;
        z-index: 100;
        color: #e0e0e0;
    }

.option-x-input.xradio {
    border-radius: 50%;
}

.option-x-input.xradio::after {
    border-radius: 50%;
}
label.label-chkbox{    
    padding: 0px;
    margin: 0px;
    line-height: 40px;
    top: 0px;
}
label.label-chkbox span {
    position: absolute;
}